<template>
  <FocusTrap>
    <div class="card">

    <div class="card-header bg-secondary header-elements-inline">
      <h5 class="card-title ">Ledger Creation</h5>
      <div class="header-elements">
        <div class="list-icons">
          <a class="list-icons-item" data-action="reload" tabindex="-1" @click="loadParents"></a>
          <a class="list-icons-item" data-action="remove" tabindex="-1" @click="closeThis"></a>
        </div>
      </div>
    </div>

    <div class="card-body">

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Name:</label>
          <div class="col-md-10">
            <input type="text"  id="txtname" class="form-control" placeholder="Enter Ledger Name here..." maxlength="100" v-if="ledger" v-model="ledger.name">
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Alias Name:</label>
          <div class="col-md-10">
            <input type="text" class="form-control" placeholder="Enter alias Name here..." maxlength="100"  v-if="ledger" v-model="ledger.alias">
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Ledger Group:</label>
          <div class="col-md-10">
            <select class="form-control" v-if="ledger" v-model="ledger.group.id" >
              <option value="1">-</option>
              <option v-for="group in ledgergroups" v-bind:value="group.id">
                {{ group.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Opening Balance:</label>
          <div class="col-md-4">
            <input type="number"  class="form-control text-right" v-if="ledger" v-model="ledger.open_bal">
          </div>
          <div class="col-md-1">
            <select id="cmbcrdr" class="form-control" v-if="ledger"  v-model="ledger.is_crdr" >
              <option value="1">Cr</option>
              <option value="-1">Dr</option>
            </select>
          </div>
        </div>

    </div>
    <div class="card-footer text-right" style="padding: 3px;padding-right: 17px;">
        <button type="button" class="btn btn-primary" @click="saveLedger">
          Save<i class="icon-paperplane ml-2"></i>
        </button>
    </div>
  </div>
  </FocusTrap>
</template>

<script>
  import {moment} from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
export default {
  name: 'LedgerForm',
  components: {

  },
  data () {
    return {
      ledger: JSON.parse('{"id":0,"status":0,"type":0,"name":"","alias":"","print_name":"","group_id":0,"group":{"id":1,"status":0,"type":0,"name":"","alias":"","print_name":"","parent_id":0,"nature":0,"affect_profit":false,"behave_like_sub_ledger":false,"net_cr_dr_balance":false,"used_for_calculation":false},"open_bal":0,"print_order":0,"gstin":"","address":{"name":"","street":"","city":"","pincode":"","state_cd":33,"email":"","telephone":"","mobile":""}}'),
      ledgergroups: [],
    }
  },
  props: {
    myledger: {
      type: Object,
      required: true,
      default: JSON.parse('{"id":0,"status":0,"type":0,"name":"","alias":"","print_name":"","group_id":0,"group":{"id":1,"status":0,"type":0,"name":"","alias":"","print_name":"","parent_id":0,"nature":0,"affect_profit":false,"behave_like_sub_ledger":false,"net_cr_dr_balance":false,"used_for_calculation":false},"open_bal":0,"print_order":0,"gstin":"","address":{"name":"","street":"","city":"","pincode":"","state_cd":33,"email":"","telephone":"","mobile":""}}')
    }
  },
  beforeMount () {
    this.ledger = this.myledger; // save props data to itself's data and deal with tms
    this.ledger.open_bal = this.ledger.open_bal.toFixed(2);
  },
  watch: {
    ledger(value) {
      console.log('myledger');
    }
  },
  created () {

    this.loadParents();
  },
  mounted () {
    const self = this;

    self.loadParents();

    $('.form-control-select2').select2();
    $('.form-control-styled').uniform();

    $('#txtname').focus();

  },
  methods: {
    loadParents(){

      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      fetch(`${process.env.VUE_APP_ROOT_API}api/accounts/ledgergroups/after/1`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          self.$data.ledgergroups =  resp.data;
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: `failed to load data!`, type:  "error"} );
      });
    },
    closeThis(){
      this.$emit('ledger_window_closed');
    },
    clear(){
      const self = this;
      self.ledger = JSON.parse('{"id":0,"status":0,"type":0,"name":"","alias":"","print_name":"","group":{"id":1,"status":0,"type":0,"name":"","alias":"","print_name":"","parent_id":0,"nature":0,"affect_profit":false,"behave_like_sub_ledger":false,"net_cr_dr_balance":false,"used_for_calculation":false},"open_bal":0, "is_crdr":1,"print_order":0,"gstin":"","address":{"name":"","street":"","city":"","pincode":"","state_cd":0,"email":"","telephone":"","mobile":""}}');
    },
    saveLedger () {

      const self = this;

      self.$data.ledger.group_id = parseInt(self.$data.ledger.group.id);
      self.$data.ledger.open_bal =  $('#cmbcrdr').val() *  Math.abs(parseFloat(self.$data.ledger.open_bal));

      if(self.$data.ledger.group.id < 1){
        alert("Invalid Ledger Group");
        return
      }

      // console.log(JSON.stringify(self.$data.ledger));

      const requestOptions = {
        method:  (self.$data.ledger.id == 0 ? 'POST' : 'PUT'),
        mode:"cors",
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.ledger)
      };

      fetch(`${process.env.VUE_APP_ROOT_API}api/accounts/ledger`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          swal ( { title: "Success!" ,  text: resp.msg, type:  "success", timer:3000} );
          self.ledger = JSON.parse('{"id":0,"status":0,"type":0,"name":"","alias":"","print_name":"","group":{"id":1,"status":0,"type":0,"name":"","alias":"","print_name":"","parent_id":0,"nature":0,"affect_profit":false,"behave_like_sub_ledger":false,"net_cr_dr_balance":false,"used_for_calculation":false},"open_bal":0, "is_crdr":1,"print_order":0,"gstin":"","address":{"name":"","street":"","city":"","pincode":"","state_cd":0,"email":"","telephone":"","mobile":""}}');
          self.$emit('ledger_saved', resp.data);
          self.clear();
          $('#txtname').focus();
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: `failed to load data!`, type:  "error"} );
      });
    }
  }
}
</script>

<style scoped>
input:focus {
  background: #feff00;

}

textarea:focus {
  background: #feff00;

}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00;
  color: #0a0a0a!important;
}
</style>
